import React, { useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";


const InputField = ({ startDate, setStartDate, startTime, setStartTime, fieldClass, labelClass, type, name, value, required, placeholder, handlechange, pattern, label, grpmd, step, inputlabel }) => {

  const [date_value, onChange] = useState(new Date());
  if (type == 'datetime' && date_value) {
    var month = date_value.getMonth() + 1
    var date = date_value.getDate() + '/' + month + '/' + date_value.getFullYear()
  }
  const MyContainer = ({ className, children }) => {
    return (
      <div>
        <div className={className}> 
          <div style={{ position: "relative" }}>{children}</div>
        </div>
      </div>
    );
  };



  return (

    <Form.Group as={Col} md={grpmd} className={`${type} ${step}`} controlId={"validation" + name}>
      <div className="custom-float">
        {label
          ? <p className="label"><Form.Label className="form-label">{label}{required ? '' : ''}</Form.Label></p>
          : ''
        }
        {type === 'timePicker' ?
          <>
            <DatePicker
              placeholderText={placeholder}
              popperPlacement='top-start'
              className={fieldClass}
              name={name}
              value={startTime}
              selected={startTime}
              onChange={(date) => setStartTime(date)}
              autocomplete="off"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat="h:mm aa"
            />
          </> : type === 'datePicker' ?
            <>
              <DatePicker
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="picker-previous" type="button">

                    </button>
                    <h4>{moment(date).format('MMMM YYYY')}  </h4>

                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="picker-next" type="button">

                    </button>
                  </div>
                )}
                selected={startDate}
                onChange={date => setStartDate(date)}
                minDate={new Date()}
                placeholderText={placeholder}
                dateFormat="dd/MM/yyyy"
                className={fieldClass}
                required={required}
                autocomplete="off"
                name={name}
                autoComplete={'' + Math.random()}
                // onChange={handlechange}
                value={startDate}
                popperPlacement='top-start'

              />
              <div class="input-icon-box"><i class="icon-angle-down"></i></div>
            </>

            : type === 'date_time' ?
              <>
                <DatePicker
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="picker-previous" type="button">

                      </button>
                      <h4>{moment(date).format('MMMM YYYY')}  </h4>

                      <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="picker-next" type="button">

                      </button>
                    </div>
                  )}
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  showTimeSelect
                  minDate={new Date()}
                  placeholderText={placeholder}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  className={fieldClass}
                  required={required}
                  autocomplete="off"
                  name={name}
                  autoComplete={'' + Math.random()}
                  // onChange={handlechange}
                  popperPlacement='top-start'
                  value={startDate}
                  filterDate={(date) => {
                    console.log(moment(date).day(), moment(date).format('dd-mm'))
                    return (moment(date).day() !== 5);
                  }}
                  calendarContainer={MyContainer}

                />
              </>

              :
              <Form.Control
                className={fieldClass}
                required={required}
                type={type}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={handlechange}
                pattern={pattern}
                autocomplete="off"
              />}

      </div>
    </Form.Group>
  )
}

export default InputField;