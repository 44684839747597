import React from 'react'
import { Button } from "react-bootstrap"
import {Row, Col, Container,Breadcrumb } from 'react-bootstrap';
import { Link } from "@StarberryUtils";

const ButtonField = ({type, name, value, fieldClass,step,handlechange,handlechange2, handlechange3, handlechange4, handlebackchange, handlebackchange2, handlebackchange3, handlebackchange4, grpmd}) => (
	<div className={`btns ${step} `} md={grpmd}>
		{step == 'stepone' &&
		<Row className="step-btns">
			<Col md={5}>
				<Button onClick={handlebackchange}  variant="" type={type} className={fieldClass}>
					Go Back
				</Button>
			</Col>
			<Col md={2}>
			</Col>
			<Col md={5}>
				<Button onClick={handlechange}  variant="" type={type} className="btn-step btn-step-next btn btn-primary">
					{name}
				</Button>
			</Col>
		</Row>
		}
		{step == 'steptwo' &&
		<Row className="step-btns">
			<Col md={5}>
				<Button onClick={handlebackchange2}  variant="" type={type} className={fieldClass}>
					Go Back
				</Button>
			</Col>
			<Col md={2}>
			</Col>
			<Col md={5}>
				<Button onClick={handlechange2}  variant="" type={type} className="btn-step btn-step-next btn btn-primary">
					{name}
				</Button>
			</Col>
		</Row>
		}
		{step == 'stepthree' &&
		<Row className="step-btns">
			<Col md={5}>
				<Button onClick={handlebackchange3}  variant="" type={type} className={fieldClass}>
					Go Back
				</Button>
			</Col>
			<Col md={2}>
			</Col>
			<Col md={5}>
				<Button onClick={handlechange3}  variant="" type={type} className="btn-step btn-step-next btn btn-primary">
					{name}
				</Button>
			</Col>
		</Row>
		}
		{step == 'stepfour' &&
		<Row className="step-btns">
			<Col md={5}>
				<Button onClick={handlebackchange4}  variant="" type={type} className={fieldClass}>
					Go Back
				</Button>
			</Col>
			<Col md={2}>
			</Col>
			<Col md={5}>
				<Button onClick={handlechange4}  variant="" type={type} className="btn-step btn-step-next btn btn-primary">
					{name}
				</Button>
			</Col>
		</Row>
		}

		{step == 'stepfive' &&
		<Row className="step-btns">
			<Col md={5}>
				<Link to="/contact"  variant="" type={type} className={fieldClass}>
					Contact Us
				</Link>
			</Col>
			<Col md={2}>
			</Col>
			<Col md={5}>
				<Button  variant="" type={type} className="btn-step btn-step-next btn btn-primary">
					{name}
				</Button>
			</Col>
		</Row>
		}

		{step != 'stepone' && step != 'steptwo' && step != 'stepthree' && step != 'stepfour' && step != 'stepfive' &&
		<Button variant="" type={type} className={fieldClass}>
			{name}
		</Button>
		}
	</div>
);

export default ButtonField;
